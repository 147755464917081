import { css } from "@emotion/core"

export const mail2U = css`
  #mail2u,
  .mail2u {
    float: left;
    width: 100%;
    text-align: center;
    border-top: 1px dashed #9abdcb;

    a {
      border:none;
      display: block;
      margin: 15px auto;
      max-width: 320px;
      padding: 0;
      text-decoration: none;
    }
    p {
      padding: 0 10px;
      text-align: center;
      margin: 0 auto;
      max-width: 90%;
      color: #00426a;
      text-decoration: none;
      font-weight: bold;
      clear: left;
    }
    img {
      width: 100%;
      max-width: 300px;
    }
  }



`
